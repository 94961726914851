<!-- eslint-disable no-plusplus -->
<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Benchmark Reference From</label>
          <flat-pickr
            :value="statusFilter"
            class="form-control"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Mould Selection</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
            @close="loadbbechmark()"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>IM Selection </label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="imFilter"
            :options="imOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:imFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>To</label>
          <flat-pickr
            :value="planFilter"
            class="form-control"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="val => val.value"
            @input="(val) => $emit('update:planFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <div v-if="showDiv === 1">
            <label>Benchmark Data </label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="moldFilter"
              :options="moldOptions2"
              class="w-100"
              :reduce="val => val.value"
              readonly
              @input="(val) => $emit('update:moldFilter', val)"
            />
          </div>
          <div v-if="showDiv === 0">
            <label>Benchmark Data </label> <br>
            <b-badge variant="light-danger">
              No Benchmark Data!
            </b-badge>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>&nbsp;</label>
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <br>
          <br>
        </b-col>
      </b-row>
      <b-row />

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BBadge, // BCardHeader
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import useAppConfig from '@core/app-config/useAppConfig'
import { ref } from '@vue/composition-api'
// import { refreshData } from './List.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // BCardHeader,
    BCardBody,
    vSelect,
    flatPickr,
    BBadge,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    planFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    imFilter: {
      type: [String, null],
      default: null,
    },
    moldFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    imOptions: {
      type: Array,
      required: true,
    },
    moldOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mold: '123',
      showDiv: null,
    }
  },
  setup() {
    const moldOptions2 = ref([])

    return {
      moldOptions2,
    }
  },
  methods: {
    async loadbbechmark() {
      const params = new URLSearchParams()
      params.append('mold', this.roleFilter)
      const result3 = await axios.post(`${useAppConfig().AxiosUrl}api_mes/mold-simulator-history/view`, params)
      const dataAPI3 = result3.data.data
      const combo3 = []
      // eslint-disable-next-line no-plusplus
      if (dataAPI3.length === 0) {
        this.showDiv = 0
      }
      for (let i = 0, len = dataAPI3.length; i < len; i++) {
        this.showDiv = 1
        combo3.push({ label: `${dataAPI3[i].urut}.  ${dataAPI3[i].machine} (${dataAPI3[i].mold}) = ${dataAPI3[i].kwhkg} kWh/kg`, value: `${dataAPI3[i].urut}.  ${dataAPI3[i].machine} (${dataAPI3[i].mold}) = ${dataAPI3[i].kwhkg} kWh/kg` })
      }
      this.moldOptions2 = combo3
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
