<template>

  <div>

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :user-data="userData"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <list-filters
      :role-filter.sync="roleFilter"
      :role-options="roleOptions"
      :im-filter.sync="imFilter"
      :mold-filter.sync="moldFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :im-options="imOptions"
      :mold-options="moldOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      style="background: border-box;margin-top: -102px;"
    >
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          />

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          />

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          />

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          />
        </b-row>
        <b-row>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          />

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          />

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <div style="text-align-last: right">
              <b-button
                variant="primary"
                @click="SaveSimulation()"
              >
                <span class="text-nowrap">SET</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </b-card-body>
      <div
        v-if="loading"
        id="proses"
        style="text-align: -webkit-center;"
      > <b-button
        variant="outline-info"
        disabled
        class="mr-1"
      >
        <b-spinner small />
        Calculating...
      </b-button>
      </div>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >

      <b-table-simple
        :busy.sync="isBusy"
        small
        caption-top
        responsive
        bordered
        outlined
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-button
              variant="outline-primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </template>
        <caption><h1>Trial Progress</h1></caption>
        <colgroup><col><col></colgroup>
        <colgroup><col><col><col></colgroup>
        <colgroup><col><col></colgroup>
        <!-- <tdead head-variant="light">
          <b-tr>
            <td rowspan="2">
              MC
            </td>
            <td rowspan="3">
              Mold
            </td>
            <td rowspan="2">
              Box
            </td>
          </b-tr>
          <b-tr>
              <td class="text-nowrap">
                kWh/kg
              </td>
              <td class="text-nowrap">
                act kg
              </td>
              <td class="text-nowrap">
                std kg
              </td>
          </b-tr>
        </tdead> -->
        <b-tbody>
          <b-tr>
            <template
              v-for="(data, index) in SimulatorData.data"
            >
              <td
                v-if="index < 10"
                class="text-nowrap"
                style="outline: auto; line-height: 25px; font-family: monospace;"
              >
                <div style="min-width: 120px">
                  <div style="text-align-last: center">
                    <strong><b-badge
                      pill
                      variant="light-info"
                      style="font-size: 13px"
                    >{{ data.machine }}</b-badge></strong>
                  </div><br>
                  {{ data.mold }}<br>
                  {{ data.shoot }} shoot<br>
                  {{ data.out }} kg<br>
                  {{ data.kwh }} kWh<br>
                  {{ data.kwhkg }} kWh/kg<br>
                  {{ data.kwhshoot }} kWh/shoot<br>
                </div>
              </td>
            </template>
          </b-tr>
          <b-tr>
            <template
              v-for="(data, index) in SimulatorData.data"
            >
              <td
                v-if="index >= 10 && index < 20"
                class="text-nowrap"
                style="outline: auto; line-height: 25px; font-family: monospace;"
              >
                <div style="min-width: 120px">
                  <div style="text-align-last: center">
                    <strong><b-badge
                      pill
                      variant="light-info"
                      style="font-size: 13px"
                    >{{ data.machine }}</b-badge></strong>
                  </div><br>
                  {{ data.mold }} <br>
                  {{ data.shoot }} shoot<br>
                  {{ data.out }} kg<br>
                  {{ data.kwh }} kWh<br>
                  {{ data.kwhkg }} kWh/kg<br>
                  {{ data.kwhshoot }} kWh/shoot<br>
                </div>
              </td>
            </template>
          </b-tr>
          <b-tr>
            <template
              v-for="(data, index) in SimulatorData.data"
            >
              <td
                v-if="index >= 20 && index < 30"
                class="text-nowrap"
                style="outline: auto; line-height: 25px; font-family: monospace;"
              >
                <div style="min-width: 120px">
                  <div style="text-align-last: center">
                    <strong><b-badge
                      pill
                      variant="light-info"
                      style="font-size: 13px"
                    >{{ data.machine }}</b-badge></strong>
                  </div><br>
                  {{ data.mold }} <br>
                  {{ data.shoot }} shoot<br>
                  {{ data.out }} kg<br>
                  {{ data.kwh }} kWh<br>
                  {{ data.kwhkg }} kWh/kg<br>
                  {{ data.kwhshoot }} kWh/shoot<br>
                </div>
              </td>
            </template>
          </b-tr>
          <b-tr>
            <template
              v-for="(data, index) in SimulatorData.data"
            >
              <td
                v-if="index >= 30 && index < 40"
                class="text-nowrap"
                style="outline: auto; line-height: 25px; font-family: monospace;"
              >
                <div style="min-width: 120px">
                  <div style="text-align-last: center">
                    <strong><b-badge
                      pill
                      variant="light-info"
                      style="font-size: 13px"
                    >{{ data.machine }}</b-badge></strong>
                  </div><br>
                  {{ data.mold }} <br>
                  {{ data.shoot }} shoot<br>
                  {{ data.out }} kg<br>
                  {{ data.kwh }} kWh<br>
                  {{ data.kwhkg }} kWh/kg<br>
                  {{ data.kwhshoot }} kWh/shoot<br>
                </div>
              </td>
            </template>
          </b-tr>
          <b-tr>
            <template
              v-for="(data, index) in SimulatorData.data"
            >
              <td
                v-if="index >= 40 && index < 50"
                class="text-nowrap"
                style="outline: auto; line-height: 25px; font-family: monospace;"
              >
                <div style="min-width: 120px">
                  <div style="text-align-last: center">
                    <strong><b-badge
                      pill
                      variant="light-info"
                      style="font-size: 13px"
                    >{{ data.machine }}</b-badge></strong>
                  </div><br>
                  {{ data.mold }} <br>
                  {{ data.shoot }} shoot<br>
                  {{ data.out }} kg<br>
                  {{ data.kwh }} kWh<br>
                  {{ data.kwhkg }} kWh/kg<br>
                  {{ data.kwhshoot }} kWh/shoot<br>
                </div>
              </td>
            </template>
          </b-tr>
        </b-tbody>
        <!-- <b-tfoot>
          <b-tr>
            <b-td
              colspan="10"
              class="text-left"
            >
              Daily Output prediction: 20661.314 kg <br>
              Daily kWh prediction: 8493.93 kWh <br>
              Daily Energy Rate Prediction: 0,411 kWh/kg <br>
            </b-td>
          </b-tr>
        </b-tfoot> -->
      </b-table-simple>
    </b-card>
    <br>
    <b-card
      no-body
      class="mb-0"
    >
      <b-row>
        <div style="margin-left: 15px">
          &nbsp;<br>
          <h4 style="line-height: 30px">
            Daily Output Prediction : {{ TotalSimulatorData.data[0].out }} kg <br>
            Daily kWh Prediction : {{ TotalSimulatorData.data[0].kwh }} kWh <br>
            Daily Energy Rate Prediction : {{ TotalSimulatorData.data[0].kwhkg }} kWh/kg <br></h4>
          &nbsp;<br></div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BSpinner, BCard, BCardBody, BTableSimple, BTr, BTbody, BTfoot, BButton, BBadge, // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import ListFilters from './ListFilters.vue'
// eslint-disable-next-line import/no-cycle
import useList from './useList'
import StoreModule from '../StoreModule'
import AddNew from './AddNew.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ListFilters,
    AddNew,

    BCard,
    BTableSimple,
    BTr,
    BTbody,
    BTfoot,
    BSpinner,
    BButton,
    BCardBody,
    BBadge,
    // BDropdown,
    // BDropdownItem,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      loading: false,
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, StoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const SimulatorData = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/mold-simulator/view`)
      SimulatorData.value = result.data
    })

    const TotalSimulatorData = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/mold-simulator-total/view`)
      TotalSimulatorData.value = result.data
    })

    const blankTask = {
      group_item: '',
      periode: '',
      stok_akhir: '',
      unrecycle: '',
      action: 'Add',
    }

    const isAddNewUserSidebarActive = ref(false)
    const userData = ref(JSON.parse(JSON.stringify(blankTask)))

    const roleOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/ctpp-mold/list`)
      const dataAPI = result.data.data
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        combo.push({ label: dataAPI[i].nama, value: dataAPI[i].nama })
      }
      roleOptions.value = combo
    })

    const moldOptions = ref([])

    const imOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/mold-simulator/view`)
      const dataAPI = result.data.data
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        combo.push({ label: `${dataAPI[i].machine} (${dataAPI[i].mold})`, value: dataAPI[i].machine })
      }
      imOptions.value = combo
    })

    const EditData = taskData => {
      userData.value = taskData
      isAddNewUserSidebarActive.value = true
    }

    const AddData = () => {
      userData.value = blankTask
      isAddNewUserSidebarActive.value = true
    }

    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      imFilter,
      moldFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {
      userData,
      AddData,
      EditData,

      // Sidebar
      isAddNewUserSidebarActive,
      SimulatorData,
      TotalSimulatorData,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      imOptions,
      moldOptions,

      // Extra Filters
      roleFilter,
      imFilter,
      moldFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    async SaveSimulation() {
      const params = new URLSearchParams()
      params.append('param', 'save')
      params.append('from', this.statusFilter)
      params.append('to', this.planFilter)
      params.append('machine', this.imFilter)
      params.append('mold', this.roleFilter)

      if (this.statusFilter === null || this.planFilter === null || this.imFilter === null || this.roleFilter === null
        || this.statusFilter === undefined || this.planFilter === undefined || this.imFilter === undefined || this.roleFilter === undefined
        || this.statusFilter === '' || this.planFilter === '' || this.imFilter === '' || this.roleFilter === ''
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            text: 'Please fill all data simulation!',
            variant: 'warning',
          },
        })
      } else {
        this.loading = true
        const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/mold-simulator/crud`, params)
        if (result.data.message === 'Data Saved') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: result.data.message,
              variant: 'success',
            },
          })
          this.refreshdata()
          // refreshData()
          // this.refetchData()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'BellIcon',
              text: result.data.message,
              variant: 'warning',
            },
          })
        }
        this.loading = false
      }
    },
    async refreshdata() {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/mold-simulator/view`)
      const dataAPI = result.data.data
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        combo.push({ label: `${dataAPI[i].machine} (${dataAPI[i].mold})`, value: dataAPI[i].machine })
      }
      this.imOptions = combo

      const result2 = await axios.get(`${useAppConfig().AxiosUrl}api_mes/mold-simulator/view`)
      this.SimulatorData = result2.data

      const result3 = await axios.get(`${useAppConfig().AxiosUrl}api_mes/mold-simulator-total/view`)
      this.TotalSimulatorData = result3.data
    },
    async showMsgBoxTwo(id, periode) {
      console.log(id)
      this.boxTwo = id
      this.$bvModal
        .msgBoxConfirm('Are You Sure To Delete This Data?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete')
            params.append('group_item', id)
            params.append('periode', periode)
            const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/stok-akhir-ctr/delete`, params)
            console.log(result)
            if (result.data.message === 'Data Deleted') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Error Deleting Data',
                  variant: 'danger',
                },
              })
              console.log(result)
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
